import React from 'react';

import Layout from 'components/Layout';

const Colophon = () => (
    <Layout>
        <div className="page-content">
            <h1>Colophon</h1>
            <div>
                abrad45.com runs on{' '}
                <a href="https://www.gatsbyjs.org/">GatsbyJS</a> and is hosted
                on <a href="https://www.netlify.com/">Netlify</a>. All the code
                is on <a href="https://github.com/abrad45/">Github</a> in a
                private repo for now. I may make it public in the future.
            </div>
        </div>
    </Layout>
);

export default Colophon;
